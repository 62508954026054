import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Tenant } from '@app/models/constants/tenants';
import { AuthService } from '@app/services/common/auth.service';
import { find, get, pull } from 'lodash';
import { tenantEnvironment } from 'src/environments/environment.common';

@Component({
	selector: 'app-tag-input',
	templateUrl: './tag-input.component.html',
	styleUrls: ['./tag-input.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: TagInputComponent }]
})
export class TagInputComponent implements OnInit, ControlValueAccessor {
	@ViewChild('tagInput') tagInputRef: ElementRef;
	@Input() tags: string[] = [];
	form: FormGroup;
	onChange: (tags: string[]) => {};
	get tenant() {
		return this.authService.tenant;
	}

	constructor(private fb: FormBuilder,
		private authService: AuthService) {}
	writeValue(obj: string[]): void {
		this.tags = obj;
	}
	registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}
	registerOnTouched(fn: any): void {}
	setDisabledState?(isDisabled: boolean): void {}

	ngOnInit() {
		this.form = this.fb.group({
			tag: ['', [Validators.minLength(4), Validators.maxLength(10)]]
		});
	}

	focusTagInput(): void {
		this.tagInputRef.nativeElement.focus();
	}

	onKeyDown(event: KeyboardEvent): void {
		const inputValue: string = this.form.controls['tag'].value;
		switch (event.key) {
			case 'Backspace':
				if (!inputValue) {
					this.removeTag();
					return;
				}
				break;
			case 'Enter':
				if (inputValue) {
					this.addTag(inputValue);
					this.form.controls['tag'].setValue('');
				}
				event.stopPropagation();
				event.preventDefault();
				break;
		}
	}

	onKeyUp(event: KeyboardEvent): void {
		const inputValue: string = this.form.controls['tag'].value;
		switch (event.key) {
			case ',':
			case ' ':
				this.addTag(inputValue);
					this.form.controls['tag'].setValue('');
				break;
			default:
				break;
		}
	}

	addTag(tag: string): boolean {

		if(this.tenant == Tenant.DEKA){
			if (tag.length < 1 || tag.length > 10) {
				return false;
			}
		}else{
			if (tag.length < 4 || tag.length > 10) {
				return false;
			}
		}

		if (tag[tag.length - 1] === ',' || tag[tag.length - 1] === ' ') {
			tag = tag.slice(0, -1);
		}

		if (!this.tags.includes(tag)) {
			this.tags.push(tag);
			this.onChange(this.tags);
			this.sortTags();
			return true;
		}

		return false;
	}

	removeTag(tag?: string): void {
		if (!!tag) {
			pull(this.tags, tag);
		} else {
			this.tags.splice(-1);
		}

		this.onChange(this.tags);
	}

	sortTags(){
		this.tags.sort();
	}
}
