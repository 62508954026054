import { AbstractControl, ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import {
	ChangeDetectorRef,
	Directive,
	ElementRef,
	Inject,
	Input,
	NgZone,
	OnDestroy,
	OnInit,
	Optional,
	Self,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CaseApiService } from 'src/core/api/case.api.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { UnionInvoiceNoteApiService } from '@app/shared/components/union-invoice-note/union-invoice-note.api.service';

@Directive()
export abstract class BaseFormControl<T = any, InputElement = any> implements ControlValueAccessor, OnInit, OnDestroy {
	@Input() label: string;
	//@Input() value: T;
	_value: T | string;
	get value(){
		return this._value
	}
	@Input() set value(newValue: T | string){
		this._value = typeof newValue === "string" ? newValue.trim() :newValue;
	}
	@Input() readonly = false;
	@Input() required = false;
	@Input() hasErrors: boolean | undefined = false;
	@Input() fieldInfo?: any;
	@Input() tableLayout?: boolean;
	@Input() autocomplete: boolean = false;
	@Input() tooltip?: string;
	@Input() tooltipPlacement?: string;
	@Input() warning?: boolean;
	/**
	 * If set to true, indicates that fields in this form can be edited by an external (unauthenticated) user
	 */
	@Input() externallyEditable: boolean = false;
	/**
	 * The external token which identifies the external user / part of the app
	 */
	@Input() externalToken: string | null = null;
	changed: boolean;
	assignmentChanged: boolean;
	selectedIndex: number;
	@ViewChild('input', { static: true }) input: ElementRef<InputElement>;
	@ViewChild('controlContainer', { read: ViewContainerRef, static: true }) controlContainerRef: ViewContainerRef;

	protected destroy$ = new Subject<boolean>();

	onChange = (_: any) => {};

	onTouched = () => {};

	get control() {
		return this.ngControl ? (this.ngControl.control as FormControl) : new FormControl();
	}

	constructor(
		@Inject(NgControl) @Self() @Optional() public ngControl: NgControl,
		protected cdr: ChangeDetectorRef,
		protected caseService: CaseApiService,
		protected unionInvoiceNoteApiService: UnionInvoiceNoteApiService,
		protected router: Router,
		protected zone: NgZone
	) {
		if (this.ngControl) {
			this.ngControl.valueAccessor = this;
		}
	}

	ngOnInit() {
		this.checkRequired();
		if (this.ngControl?.control) {
			this.ngControl.control.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.checkRequired());
		}
	}

	assignFieldInfo() {
		!this.tableLayout && (this.label = this.fieldInfo.label);
		this.readonly = this.fieldInfo.classes.includes('disabled');
		this.required = this.fieldInfo.classes.includes('sla-field');
		this.hasErrors = this.fieldInfo.classes.includes('has-error');
		this.tooltip = this.fieldInfo.tooltip;
		this.warning = this.fieldInfo.classes.includes('has-warning');
		this.tooltipPlacement = (this.fieldInfo.tooltipPlacement || '')
			.replace('auto bottom', 'bottom-left bottom bottom-right')
			.replace('auto bottom-left', 'bottom-left bottom bottom-right')
			.replace('auto bottom-right', 'bottom-right bottom bottom-left')
			.replace('auto right', 'right left')
			.replace('auto left', 'left right')
			.replace('auto top', 'top-left top top-right')
			.replace('auto top-left', 'top-left top top-right')
			.replace('auto top-right', 'top-right top top-left');
	}

	checkRequired() {
		if (this.ngControl && this.ngControl.control) {
			if (this.ngControl.control.validator) {
				const validationErrors = this.ngControl.control.validator({} as AbstractControl);
				this.required = !!(validationErrors && validationErrors['required']);
			} else {
				this.required = false;
			}
		}
	}

	writeValue(value: any): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	changeValue(val: string, title: string = '', useTitle = false) {
		let request;

		if (useTitle) {
			val = title;
		}
		// do not attempt to format an empty string
		// -> delete request
		if (this.fieldInfo.type === 'DATE' && val) {
			val = moment(val, 'DD.MM.YYYY').format('YYYY-MM-DD');
		}

		if (this.fieldInfo.parent === 'CASE_ACCOUNT_ASSIGNMENT') {
			switch (this.fieldInfo.key) {
				case 'currency':
					request = this.caseService.changeCurrencyOfAccountAssignment(this.fieldInfo.parentID, val);
					break;
				default:
					request = this.autocomplete
						? this.caseService.updateAssignmentLookupField(
								this.fieldInfo.key,
								this.fieldInfo.parentID,
								val,
								this.fieldInfo.label,
								title
						  )
						: this.caseService.editAssignmentProperty(
								this.fieldInfo.parentID,
								this.fieldInfo.key,
								val,
								this.fieldInfo.label
						  );
			}
		} else if (this.fieldInfo.parent === 'CASE_ACCOUNT_ASSIGNMENT_POS') {
			request = this.autocomplete
				? this.caseService.updateAssignmentPositionLookupField(
						this.fieldInfo.key,
						this.fieldInfo.parentID,
						val,
						this.fieldInfo.label,
						title
				  )
				: this.caseService.editAssignmentPositionProperty(
						this.fieldInfo.parentID,
						this.fieldInfo.key,
						val,
						this.fieldInfo.label,
						this.fieldInfo.recurringPaymentFieldGroupId
				  );
		} else if (this.fieldInfo.parent === 'CASE_CORPORATE_ASSIGNMENT') {
			request = this.autocomplete
				? this.caseService.updateCorporateAssignmentLookupField(
						this.fieldInfo.key,
						this.fieldInfo.parentID,
						val,
						this.fieldInfo.label,
						title
				  )
				: this.caseService.editCorporateAssignmentProperty(
						this.fieldInfo.parentID,
						this.fieldInfo.key,
						val,
						this.fieldInfo.label
				  );
		} else if (this.fieldInfo.parent === 'CASE_PM_ASSIGNMENT') {
			request = this.autocomplete
				? this.caseService.updatePMAssignmentLookupField(
						this.fieldInfo.key,
						this.fieldInfo.parentID,
						val,
						this.fieldInfo.label,
						title
				  )
				: this.caseService.editPMAssignmentProperty(
						this.fieldInfo.parentID,
						this.fieldInfo.key,
						val,
						this.fieldInfo.label
				  );
		} else if (this.fieldInfo.parent === 'CASE_CREDITOR') {
			request = this.caseService.editCaseCreditorDataProperty(
				this.fieldInfo.parentID,
				this.fieldInfo.key,
				val,
				this.fieldInfo.label,
				this.autocomplete ? title : '',
				this.autocomplete && !val
			);
		} else if (this.fieldInfo.parent === 'CASE_CREDITOR_BANK') {
			request = this.autocomplete
				? this.caseService.editCaseCreditorBankLookupField(
						this.fieldInfo.parentID,
						this.fieldInfo.key,
						val,
						this.fieldInfo.label,
						title
				  )
				: this.caseService.editCaseCreditorBankProperty(
						this.fieldInfo.parentID,
						this.fieldInfo.key,
						val,
						this.fieldInfo.label
				  );
		} else if (this.fieldInfo.parent === 'CASE_CAPTURED_CREDITOR_BANK') {
			request = this.autocomplete
				? this.caseService.editCapturedCreditorBankLookupField(
						this.fieldInfo.parentID,
						this.fieldInfo.key,
						val,
						this.fieldInfo.label,
						title
				  )
				: this.caseService.editCapturedCaseCreditorBankProperty(
						this.fieldInfo.parentID,
						this.fieldInfo.key,
						val,
						this.fieldInfo.label
				  );
		} else if (this.fieldInfo.parent === 'CASE_DATA_POS') {
			request = this.autocomplete
				? this.caseService.updatePositionLookupField(
						this.fieldInfo.key,
						this.fieldInfo.parentID,
						val,
						this.fieldInfo.label,
						title
				  )
				: this.caseService.editCasePositionProperty(
						this.fieldInfo.parentID,
						this.fieldInfo.key,
						val,
						this.fieldInfo.label
				  );
		} else if (this.fieldInfo.parent === 'CASE_REMINDER_LIST') {
			request = this.caseService.editCaseReminderListProperty(
				this.fieldInfo.parentID,
				this.fieldInfo.key,
				val,
				this.fieldInfo.label
			);
		} else if (this.fieldInfo.parent === 'CASE_DEBITOR_INVOICE') {
			request = this.autocomplete
				? this.caseService.updateDebitorInvoiceLookupField(
						this.fieldInfo.key,
						this.fieldInfo.parentID,
						val,
						this.fieldInfo.label,
						title
				  )
				: this.caseService.editDebitorInvoiceProperty(
						this.fieldInfo.parentID,
						this.fieldInfo.key,
						val,
						this.fieldInfo.label
				  );
		} else if (this.fieldInfo.parent === 'CASE_RECURRING_PAYMENT_PLAN') {
			request = this.caseService.editRecurringPaymentPlanProperty(
				this.fieldInfo.parentID,
				this.fieldInfo.key,
				val,
				this.fieldInfo.label
			);
		} else if (this.fieldInfo.parent === 'CASE_UNION_INVOICE_ACCOMPANYING_NOTE') {
			if (this.externallyEditable && this.externalToken) {
				request = this.unionInvoiceNoteApiService.editFieldByExternalUser(
					this.fieldInfo.parentID,
					this.fieldInfo.key,
					val,
					this.externalToken
				);
			} else {
				request = this.unionInvoiceNoteApiService.editField(this.fieldInfo.parentID, this.fieldInfo.key, val);
			}
		} else {
			request = this.autocomplete
				? this.caseService.updateLookupField(
						this.fieldInfo.key,
						this.fieldInfo.parentID,
						val,
						this.fieldInfo.label,
						title
				  )
				: this.caseService.editCaseDataProperty(
						this.fieldInfo.parentID,
						this.fieldInfo.key,
						val,
						this.fieldInfo.label
				  );
		}

		request.subscribe(
			() => {
				if (
					this.fieldInfo.key === 'caseDocumentCategory' ||
					this.fieldInfo.key === 'assetType' ||
					this.fieldInfo.key === 'order'
				) {
					this.assignmentChanged = true;
				}
				if (this.fieldInfo.parent === 'CASE_DEBITOR_INVOICE') {
					// this.$rootScope.$broadcast('debitorInvoiceFieldChanged', {
					//   show: true,
					//   debitorInvoiceID: this.fieldInfo.parentID
					// });
				}
				this.changed = false;
				this.selectedIndex = -1;
			},
			(error: any) => {
				console.log(error);
				if (error.status === 406) {
					if (this.fieldInfo.key === 'caseEditor') {
						this.router.navigate([`/case/${this.fieldInfo.rootID}/case-editor-change/${val}`], {
							queryParams: {
								id: this.fieldInfo.parentID,
								editorID: val
							}
						});
					}
				}
			}
		);
	}

	ngOnDestroy() {
		this.destroy$.next(false);
	}
}
