import {
	ChangeDetectorRef,
	Component,
	ContentChildren,
	EventEmitter,
	Input,
	Output,
	QueryList,
	ViewChild
} from '@angular/core';
import { TabItemDirective } from '@app/shared/components/tab-set/tab-item.directive';
import { NgbConfig, NgbNav, NgbNavChangeEvent, NgbNavConfig, NgbNavItem } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-tab-set',
	templateUrl: './tab-set.component.html',
	styleUrls: ['./tab-set.component.scss']
})
export class TabSetComponent {
	@ViewChild(NgbNav, { static: true }) navEl: NgbNav;
	@Input() activeId: string | undefined;
	@Input() isCollapsed: boolean | undefined;
	@Input() renderInside: boolean = true;
	@Output() navChange: EventEmitter<NgbNavChangeEvent> = new EventEmitter<NgbNavChangeEvent>();
	@Output() collapsedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
	@ContentChildren(TabItemDirective) tabs: QueryList<TabItemDirective> | undefined;

	dummyNgbNav: NgbNav;

	constructor(private cdRef: ChangeDetectorRef) {
		this.dummyNgbNav = new NgbNav('dummy', new NgbNavConfig(new NgbConfig()), cdRef, null);
		this.dummyNgbNav.items = new QueryList<NgbNavItem>();
	}

	onNavChange(e: NgbNavChangeEvent) {
		this.activeId = e.nextId;
		this.navChange.emit(e);
	}

	onTabClick() {
		if (this.isCollapsed === true) {
			this.isCollapsed = false;
			this.collapsedChange.emit(this.isCollapsed);
		}
	}

	toggleCollapsed() {
		this.isCollapsed = !this.isCollapsed;
		this.collapsedChange.emit(this.isCollapsed);
	}
}
