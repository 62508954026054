import { ICompactCase } from '@app/models/dto';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	NgZone,
	OnChanges,
	OnInit,
	Optional,
	Output,
	Self,
	SimpleChanges,
	TemplateRef,
	ViewChild
} from '@angular/core';
import { GridDataSource, IGridLoadResponse } from '@app/models/components';
import { BaseFormControl } from '@app/shared/components/base-form-control/base-form-control';
import { AutocompleteVM } from 'src/app/smart-invoice/case/models/case.model';
import { NgControl } from '@angular/forms';
import { CaseApiService } from 'src/core/api/case.api.service';
import { TranslatePipe } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FabricTargetFieldService } from '../../../app/smart-invoice/fabric/services/fabric-target-field.service';
import { UnionInvoiceNoteApiService } from '@app/shared/components/union-invoice-note/union-invoice-note.api.service';
import { max } from 'lodash';
import { Observable, Subscription } from 'rxjs';

enum KEYCODES {
	ARROWDOWN = 'ArrowDown',
	ARROWUP = 'ArrowUp',
	ENTER = 'Enter',
	TAB = 'Tab',
	HOME = 'Home',
	END = 'End'
}

@Component({
	selector: 'app-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	// eslint-disable-next-line @angular-eslint/no-host-metadata-property
	host: { class: 'app-input' }
})
export class InputComponent extends BaseFormControl<string, HTMLInputElement> implements OnChanges, OnInit {
	data: IGridLoadResponse<ICompactCase> = {};
	choices?: any[];
	@Input() lastItemInfo?: string;
	lastItemClasses: string;
	colsLength: number;
	autocompleteText: boolean;
	service: Observable<AutocompleteVM>;
	serviceSubscription: Subscription;
	@Input() type: string = 'text';
	@Input() minValue: number;
	@Input() lookup: boolean = false;
	@Input() autocompleteHeader: string = this.translatePipe.transform('AUTOCOMPLETE.HEADER');
	@Input() autocompleteOptionTemplate?: TemplateRef<any>;
	@Input() autocompleteOptionValueKey: string = 'value';
	@Input() autocompleteOptionLabelKey: string = 'label';
	@Input() maxlength?: string;
	@Input() placeholder?: string;
	@Input() name?: string;
	@Input() id?: string;
	@Input() options?: any[];
	@Input() gridColumns: any[] = [];
	@Input() dataSource: GridDataSource<any>;
	@Input() width?: number;
	@Input() spacePressedStopPropagate?: boolean;
	@Input() numberOfRows: number = 5;
	@Input() disabled: boolean;
	@Input() lookupSAP: boolean;
	@Input() dataSAP: any;
	@Input() btnLabel: string;
	@Input() modalTitle: string;
	@Input() filterInput?: boolean;
	@Output() valueChange = new EventEmitter();
	@Output() blur = new EventEmitter();
	@Output() focus = new EventEmitter();
	@Output() moreSelect = new EventEmitter();
	@ViewChild('choiceTemplate', { static: true }) choiceTemplate: TemplateRef<any>;
	@ViewChild(NgbDropdown) dropdown: NgbDropdown;
	@Output() tabPressed = new EventEmitter<any>();

	public fieldInfoType: string | undefined;
	@Input() fieldInfoTypeOverride: string | undefined;

	get translateParam() {
		return {
			total: this.data.totalCount
				? this.data.totalCount - this.numberOfRows
				: this.data.items
				? this.data.items.length - this.numberOfRows
				: 0
		};
	}

	get showMore() {
		return this.data.totalCount
			? this.data.totalCount - this.numberOfRows > 0
			: this.data.items
			? this.data.items.length - this.numberOfRows > 0
			: false;
	}

	constructor(
		@Inject(NgControl) @Self() @Optional() public override ngControl: NgControl,
		protected override cdr: ChangeDetectorRef,
		protected override zone: NgZone,
		protected override caseService: CaseApiService,
		protected override unionInvoiceNoteApiService: UnionInvoiceNoteApiService,
		private translatePipe: TranslatePipe,
		protected override router: Router,
		private modalService: NgbModal,
		private elRef: ElementRef,
		private fabricTargetFieldService: FabricTargetFieldService
	) {
		super(ngControl, cdr, caseService, unionInvoiceNoteApiService, router, zone);
	}

	override ngOnInit() {
		super.ngOnInit();
		this.fieldInfoType = this.fieldInfoTypeOverride ?? this.fieldInfo?.type;
	}

	ngOnChanges(changes: SimpleChanges) {
		this.fieldInfo && this.assignFieldInfo();
		if (this.input) {
			const props = ['type', 'id', 'name'] as const;
			props.forEach((prop) => {
				if (changes[prop] && this.input) {
					this.input.nativeElement[prop] = <string>this[prop];
				}
			});
		}
	}

	isDate(fieldinfo: string) {
		return fieldinfo == 'DATE';
	}

	isText(fieldinfo: string) {
		return fieldinfo == 'TEXT';
	}

	isTextarea(fieldinfo: string) {
		return fieldinfo == 'TEXT_AREA';
	}

	returnInnerHtml() {
		this.lastItemInfo ? this.lastItemInfo : "'HEADER.SEARCH.LAST_ITEM' | translate: translateParam";
	}

	override assignFieldInfo() {
		super.assignFieldInfo();
		this.fieldInfo.maxLength ? (this.maxlength = this.fieldInfo.maxLength) : (this.maxlength = undefined);

		if (this.fieldInfo.type === 'AUTOCOMPLETE_SELECT' || this.fieldInfo.type === 'SELECT_WITH_CHOICES') {
			this.autocomplete = true;
			this.value = this.fieldInfo.selectValue?.displayTitle || '';
			this.autocompleteOptionValueKey = 'displayTitle';
			this.autocompleteOptionTemplate = this.choiceTemplate;
		} else {
			if (
				document.activeElement === this.elRef.nativeElement.querySelector('input') &&
				this.fieldInfo.type == 'CURRENCY'
			) {
				this.value = this.fieldInfo.textValue?.editValue || '';
			} else {
				this.value = this.fieldInfo.textValue?.displayValue || '';
			}
		}

		if (this.fieldInfo.autocompleteText) {
			this.autocompleteText = true;
			this.autocompleteHeader = '';
			this.autocompleteOptionValueKey = 'displayTitle';
			this.autocompleteOptionTemplate = this.choiceTemplate;
		}

		if (this.fieldInfo.type === 'DATE') {
			this.placeholder = 'TT.MM.JJJJ';
		}
	}

	initChoices(needle: string) {
		if (!this.fieldInfo) return;

		if (this.serviceSubscription) {
			this.serviceSubscription.unsubscribe();
		}

		if (this.fieldInfo.type === 'TEXT') {
			if (needle.length == 0 && this.fieldInfo.choices.length != 0) {
				this.choices = this.fieldInfo.choices;
			} else {
				this.choices = this.fabricTargetFieldService.prefixSearch(needle, 1, 5);
			}
		}

		if (this.fieldInfo.type === 'DATE') {
			if (this.fieldInfo.choices.length != 0) {
				this.choices = this.fieldInfo.choices;
			}
		}

		if (this.fieldInfo.type === 'SELECT_WITH_CHOICES' || this.fieldInfo.type === 'DATE') {
			const lcNeedle = needle.toLowerCase().trim();
			if (!lcNeedle) {
				this.choices = this.fieldInfo.choices;
			} else {
				this.choices = this.fieldInfo.choices.filter((v: any) => {
					if (typeof v.searchValue !== 'undefined' && v.searchValue !== null) {
						return v.searchValue.indexOf(lcNeedle) !== -1;
					}
					return v.extendedTitle.toLowerCase().indexOf(lcNeedle) !== -1;
				});
			}
		}

		if (this.fieldInfo.type === 'AUTOCOMPLETE_SELECT') {
			if (this.fieldInfo.parent === 'CASE_ACCOUNT_ASSIGNMENT') {
				this.service = this.caseService.autocompleteAccountAssignment(
					this.fieldInfo.key,
					this.fieldInfo.parentID,
					needle
				);
			} else if (this.fieldInfo.parent === 'CASE_ACCOUNT_ASSIGNMENT_POS') {
				this.service = this.caseService.autocompleteAccountAssignmentPos(
					this.fieldInfo.key,
					this.fieldInfo.parentID,
					needle
				);
			} else if (this.fieldInfo.parent === 'CASE_CORPORATE_ASSIGNMENT') {
				this.service = this.caseService.autocompleteCorporateAccountAssignment(
					this.fieldInfo.key,
					this.fieldInfo.parentID,
					needle
				);
			} else if (this.fieldInfo.parent === 'CASE_PM_ASSIGNMENT') {
				this.service = this.caseService.autocompletePMAccountAssignment(
					this.fieldInfo.key,
					this.fieldInfo.parentID,
					needle
				);
			} else if (this.fieldInfo.parent === 'CASE_DATA_POS') {
				this.service = this.caseService.autocompleteCaseDataPos(
					this.fieldInfo.key,
					this.fieldInfo.parentID,
					needle
				);
			} else {
				this.service = this.caseService.autocomplete(this.fieldInfo.key, this.fieldInfo.parentID, needle);
			}

			this.serviceSubscription = this.service.subscribe((response: AutocompleteVM) => {
				this.choices = response?.results || [];
				this.lastItemInfo = response?.lastItemInfo || '';
				this.lastItemClasses = response?.lastItemClasses || '';
				this.colsLength = this.choices[0].rows?.length ? this.choices[0].rows?.length : 0;
			});
		}

		if (this.choices?.length != 0) {
			this.autocompleteHeader = this.translatePipe.transform(
				needle ? 'AUTOCOMPLETE.RESULTS' : 'AUTOCOMPLETE.SUGGESTIONS',
				{ needle }
			);
		} else {
			this.autocompleteHeader = '';
		}
	}

	handleInput(event: any) {
		this.handleSelect(event.target.value);
		this.onChange(event.target.value);
		this.getData(event.target.value);

		if ((!this.autocomplete && this.fieldInfo) || this.value.length === 0) {
			this.changed = true;
		}
	}

	useTitle() {
		if (this.fieldInfo) {
			return !(this.fieldInfo.type == 'AUTOCOMPLETE_SELECT' || this.fieldInfo.type === 'SELECT_WITH_CHOICES');
		}

		return false;
	}

	onDrop() {
		if (this.dropdown && !this.dropdown.isOpen()) {
			this.dropdown.open();
		}
	}

	onKeyDown(event: KeyboardEvent) {
		const keyCode = event.key;

		if (this.fieldInfo?.type === 'TEXT_AREA' && event.shiftKey) {
			return;
		}

		if (this.autocomplete || this.autocompleteText) {
			if (this.dropdown && !this.dropdown.isOpen()) {
				this.dropdown.open();
			}

			switch (keyCode) {
				case KEYCODES.ARROWDOWN: {
					const items = this.choices || this.data?.items;
					if (items && this.selectedIndex < items.length - 1) {
						this.selectedIndex++;
					}
					event.preventDefault();
					return;
				}
				case KEYCODES.ARROWUP: {
					if (this.selectedIndex > 0) {
						this.selectedIndex--;
					}
					event.preventDefault();
					return;
				}
				case KEYCODES.TAB: {
					this.dropdown.close();
					if (!this.changed && this.selectedIndex === -1) {
						// if nothing changed do not send changes to backend
						this.elRef.nativeElement.querySelector('input').focus();
						return;
					}
				}
				case KEYCODES.ENTER: {
					if (keyCode === KEYCODES.ENTER) {
						// maybe do not prevent default, when TAB was pressed
						event.preventDefault();
						event.stopPropagation();
					}
					if (this.fieldInfo) {
						let choice = this.choices ? this.choices[this.selectedIndex] : null;
						// exclude !this.fieldInfo.autocompleteText to avoid forcing the user to take some random text that happens to be the only option in the dropdown
						if (!choice && !this.fieldInfo.autocompleteText) {
							const directMatch = this.choices?.find((choice) => choice.displayTitle === this.value);
							const onlyOneOption = this.choices?.length === 1 ? this.choices?.shift() : undefined;

							if (this.value != '') {
								choice = directMatch ?? onlyOneOption;
							} else {
								choice = '';
							}
						}
						if (choice) {
							this.changeValue(choice.id, choice.displayTitle, this.useTitle());
							this.dropdown.close();
						} else if (this.autocompleteText) {
							this.changeValue(this.value);
						} else {
							!this.value && this.changeValue(this.value);
						}
					} else {
						const item = this.data?.items ? this.data.items[this.selectedIndex] : null;
						if (item) {
							this.valueChange.emit(item);
							this.dropdown.close();
							this.value = '';
						}
					}
					this.elRef.nativeElement.querySelector('input').focus();
					return;
				}
			}
		} else if (this.fieldInfo) {
			switch (keyCode) {
				case KEYCODES.TAB:
				case KEYCODES.ENTER: {
					this.changed && this.changeValue(this.value);
					this.dropdown.close();
					if (keyCode === KEYCODES.ENTER) {
						this.onFocus(new FocusEvent('dummy'));
					}
					this.elRef.nativeElement.querySelector('input').focus();
				}
			}
		}

		if (this.type === 'number') {
			const invalidChars = ['+', 'e', 'E'];
			if (invalidChars.includes(event.key)) {
				event.preventDefault();
				return;
			}
		}

		if (this.spacePressedStopPropagate && event.keyCode === 32) {
			event.stopPropagation();
		}

		// For some reason the HOME and END keys are not working in the input fields.
		// Even removing the onKeyDown listener won't work, maybe it is related to the ngDropdownToggle directive?
		// These following lined simulate the HOME and END logic to move the cursor to the right position
		switch (keyCode) {
			case KEYCODES.HOME: {
				this.input?.nativeElement.setSelectionRange(0, 0);
				return;
			}
			case KEYCODES.END: {
				this.input?.nativeElement.setSelectionRange(this.value.length, this.value.length);
			}
		}

		this.selectedIndex = -1;
	}

	getData(searchStr: any) {
		if (this.dataSource) {
			this.data = {};
			this.dataSource.loadFn({ searchStr: searchStr }).subscribe((x) => (this.data = x));
		}

		if (this.autocomplete || this.autocompleteText || this.fieldInfo?.choices?.length != 0) {
			this.initChoices(this.value || '');
		}
	}

	handleSelect(value: string = '') {
		this.value = value;
		this.valueChange.emit(value);
	}

	handleSelectDataItem(value: any) {
		this.valueChange.emit(value);
	}

	handleMoreSelect() {
		this.moreSelect.emit();
	}

	onFocus(event: any) {
		if (this.autocomplete) {
			this.selectedIndex = -1;
			event.target?.select();
		}

		if (
			!this.readonly &&
			this.fieldInfo &&
			(this.fieldInfo.type === 'PERCENT' || this.fieldInfo.type === 'CURRENCY') &&
			this.fieldInfo.textValue?.editValue
		) {
			this.value = this.fieldInfo.textValue.editValue;
		}
		this.getData(this.value || '');
		if (this.value?.length) {
			this.input?.nativeElement.setSelectionRange(0, this.value.length);
		}
		this.focus.emit(event);
	}

	onBlur(event: FocusEvent) {
		// do not clear values when any dropdown looses focus, BUT the ACTUAL INPUT
		// in which case the relatedTarget would be NOT an auto-complete-container 💩
		const fakeBlur = (<HTMLElement>event.relatedTarget)?.classList?.contains('auto-complete-container');
		if (this.fieldInfo && !fakeBlur) {
			this.value = this.fieldInfo.textValue?.displayValue || this.fieldInfo.selectValue?.displayTitle || '';
		}
		if (!fakeBlur) this.changed = false;
		this.blur.emit(event);
	}

	onlySpaces(str: any) {
		if (str && str instanceof String) return str.trim().length === 0;
		return false;
	}

	openSAPCreditorSearchDialog() {
		const params = {
			id: this.dataSAP.caseId,
			caseDataId: this.dataSAP.caseDataId,
			companyCode: this.dataSAP.creditor.searchCompanyCode,
			isCreditor: 'true',
			postalCode: this.dataSAP.creditor.searchPostalCode,
			creditorSummaryLine: this.dataSAP.creditor.creditorSummaryLine
		};

		this.router.navigate([`/case/${this.dataSAP.caseId}/search/sap-creditor`], { queryParams: params });
	}
	openSAPDebitorSearchDialog() {
		const params = {
			id: this.dataSAP.caseID,
			caseDataId: this.dataSAP.caseDataId,
			companyCode: this.dataSAP.debitor.searchCompanyCode,
			isCreditor: 'false',
			postalCode: this.dataSAP.debitor.searchPostalCode,
			creditorSummaryLine: this.dataSAP.debitor.creditorSummaryLine
		};
		this.router.navigate([`/case/${this.dataSAP.caseId}/search/sap-creditor`], { queryParams: params });
	}
	openDialog() {
		if (this.fieldInfo.dialog == 'main.case.lookupAssignmentData') {
			this.router.navigate(
				[
					`/case/${this.fieldInfo.dialogParams.id}/lookup-assignment-data/${this.fieldInfo.dialogParams.what}/${this.fieldInfo.dialogParams.assignmentID}`
				],
				{
					queryParams: {
						positionID: this.fieldInfo.dialogParams.positionID,
						sort: this.fieldInfo.dialogParams.sort,
						notBookable: this.fieldInfo.dialogParams.notBookable,
						page: 0,
						size: 15
					}
				}
			);
		} else if (this.fieldInfo.dialog == 'main.case.lookupCaseData') {
			this.router.navigate(
				[`/case/${this.fieldInfo.dialogParams.id}/lookup-case-data/${this.fieldInfo.dialogParams.what}`],
				{
					queryParams: {
						caseDataID: this.fieldInfo.dialogParams.caseDataID,
						sort: this.fieldInfo.dialogParams.sort,
						state: this.fieldInfo.dialogParams.state,
						page: 0,
						size: 15
					}
				}
			);
		}
	}
	openLookupDialog(content: any) {
		this.modalService.open(content, { size: 'xl' });
	}
}
