import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/common/auth.service';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private authService: AuthService) {}

	 async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
	 let result: any = await this.authService.getMe().toPromise()
	 .then(data => {
		return true;
	 })
	 .catch(error => {
		this.authService.removeAuthInfo();
		return this.router.parseUrl('/login');
	 });

	 return result;
	}
}
