import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Manual } from '@app/models/dto/common/manual.dto';
import { BaseApiService } from './base.api.service';
import { Observable } from 'rxjs/internal/Observable';
import { CaseVM } from 'src/app/smart-invoice/case/models/case.model';

@Injectable({
	providedIn: 'root'
})
export class FileApiService extends BaseApiService {
	constructor(http: HttpClient) {
		super(http);
	}

	getAllManuals(lang: string) {
		return this.http.get<Manual[]>(`${this.baseUrl}/manual-file/get-list/${lang}`);
	}

	uploadAdditionalManual(file: File, title: string, language: string) {
		let formParams = new FormData();
		formParams.append('file', file);
		formParams.append('title', title);
		formParams.append('language', language);
		return this.http.post(`${this.baseUrl}/manual-files/create`, formParams, {
			reportProgress: true,
			observe: 'events'
		});
	}

	uploadAdditional(file: File, caseId: number, comment: string = ""): Observable<HttpEvent<CaseVM>> {
		let formParams = new FormData();
		formParams.append('file', file);
		formParams.append('caseID', caseId.toString());
		formParams.append('comment', comment);
		return this.http.post<CaseVM>(`${this.baseUrl}/pdf-files/additional`, formParams, {
			reportProgress: true,
			observe: 'events'
		});
	}

	uploadPdfFiles(file: File, fastLane: boolean, assetType: string, ocr: boolean = false) {
		let formParams = new FormData();
		formParams.append('file', file);
		formParams.append('fastLane', fastLane.toString());
		formParams.append('assetType', assetType);
		formParams.append('ocr', ocr.toString());
		return this.http.post(`${this.baseUrl}/pdf-files`, formParams, {
			reportProgress: true,
			observe: 'events'
		});
	}

	uploadFromExternalApproval(file: File, externalReleaseToken: string | null) {
		let formParams = new FormData();
		formParams.append('file', file);
		return this.http.post(`${this.baseUrl}/external-approval-pdf-file/${externalReleaseToken}`, formParams, {
			reportProgress: true,
			observe: 'events'
		});
	}

	removeFile(fileId: number) {
		return this.http.delete(`${this.baseUrl}/pdf-files/${fileId}`);
	}

	setAsMain(fileId: number) {
		return this.http.post(`${this.baseUrl}/pdf-file/set-main/${fileId}`, null);
	}
}
