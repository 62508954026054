import { FileDto } from '@app/models/dto/common/file.dto';
import { FileApiService } from '../../api/file.api.service';
import { Injectable } from '@angular/core';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { Observable, of, map } from 'rxjs';
import { TranslatePipe } from '@ngx-translate/core';
import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { ToastService } from '../common/toast.service';
import { AuthService } from '../common/auth.service';
import { environment } from 'src/environments/environment';
import { CaseVM } from '../../../app/smart-invoice/case/models/case.model';

@Injectable({
	providedIn: 'root'
})
export class FileDataService {
	constructor(
		private api: FileApiService,
		private translatePipe: TranslatePipe,
		private toastService: ToastService,
		private authService: AuthService
	) {}

	getAllManuals(lang: string) {
		return this.api.getAllManuals(lang);
	}

	uploadAdditionalManual(file: File, title: string, language: string) {
		let fileRes = new FileDto();
		return this.api.uploadAdditionalManual(file, title, language).pipe(
			map((e) => {
				switch (e.type) {
					case HttpEventType.UploadProgress:
						if (e.total) fileRes.progress = Math.round((100 / e.total) * e.loaded);
						break;
					case HttpEventType.Response:
						fileRes.result = e.body;
						fileRes.successMsg = this.translatePipe.transform('HELP.MANUALS_UPLOAD.SUCCESS');
						fileRes.normalSuccessMsg = this.translatePipe.transform('HELP.MANUALS_UPLOAD.NORMAL_SUCCESS');
						return fileRes;
					default:
						break;
				}
				if (e instanceof HttpErrorResponse && e.status > 0) {
					fileRes.errorMsg = e.status + ': ' + e.message;
					fileRes.fileName = file.name;
					fileRes.error = e.error;
				}
				return fileRes;
			})
		);
	}

	uploadPdfFiles(file: File, fastLane: boolean, assetType: string, ocr: boolean = false) {
		let fileRes = new FileDto();
		return this.api.uploadPdfFiles(file, fastLane, assetType, ocr).pipe(
			map((e) => {
				switch (e.type) {
					case HttpEventType.UploadProgress:
						if (e.total) fileRes.progress = Math.round((100 / e.total) * e.loaded);
						break;
					case HttpEventType.Response:
						fileRes.result = e.body;
						fileRes.fileName = fileRes?.result?.entries[0]?.originalFileName;
						fileRes.fileSize = fileRes?.result?.entries[0]?.formattedFileSize;
						let t1 = this.translatePipe.transform('UPLOAD.UPLOADED.SUCCESS', {
							fileName: fileRes?.result?.entries[0]?.originalFileName,
							fileSize: fileRes?.result?.entries[0]?.formattedFileSize
						});
						let t2 = this.translatePipe.transform('UPLOAD.UPLOADED.NORMAL_SUCCESS', {
							caseID: fileRes?.result?.entries[0]?.caseID
						});
						let t3 = this.translatePipe.transform('UPLOAD.UPLOADED.FASTLANE_SUCCESS', {
							caseID: fileRes?.result?.entries[0]?.caseID
						});
						if (fileRes.result) {
							fileRes.entries = [];
							for (const entry of fileRes.result.entries) {
								fileRes.entries.push({
									caseID: entry.caseID,
									successMsg: t1,
									normalSuccessMsg: t2,
									fastlaneSuccessMsg: t3,
									nonCaseMessageIsError: entry.nonCaseMessageIsError,
									nonCaseMessage: entry.nonCaseMessage
								});
							}
						}
						return fileRes;
					default:
						break;
				}
				if (e instanceof HttpErrorResponse && e.status > 0) {
					fileRes.errorMsg = e.status + ': ' + e.message;
					fileRes.fileName = file.name;
					fileRes.error = e.error;
				}
				return fileRes;
			})
		);
	}

	uploadFromExternalApproval(file: File, externalReleaseToken: string | null) {
		let fileRes = new FileDto();
		return this.api.uploadFromExternalApproval(file, externalReleaseToken).pipe(
			map((e) => {
				switch (e.type) {
					case HttpEventType.UploadProgress:
						if (e.total) fileRes.progress = Math.round((100 / e.total) * e.loaded);
						break;
					case HttpEventType.Response:
						fileRes.result = e.body;
						fileRes.fileName = fileRes.result.fileName;
						fileRes.fileSize = fileRes.result.fileSize;
						fileRes.shortFileName = fileRes.result.shortFileName;
						fileRes.id = fileRes.result.id;
						return fileRes;
					default:
						break;
				}
				if (e instanceof HttpErrorResponse && e.status > 0) {
					fileRes.errorMsg = e.status + ': ' + e.message;
					fileRes.fileName = file.name;
					fileRes.error = e.error;
				}
				return fileRes;
			})
		);
	}

	uploadAdditional(file: File, caseId: number, comment: string = ""): Observable<FileDto> {
		let apiObs: Observable<FileDto> = of();
		let fileRes = new FileDto();
		fileRes.isProgress = true;
		apiObs = this.api.uploadAdditional(file, caseId, comment).pipe(
			map((e) => {
				switch (e.type) {
					case HttpEventType.UploadProgress:
						if (e.total) fileRes.progress = Math.round((100 / e.total) * e.loaded);
						break;
					case HttpEventType.Response:
						fileRes.isProgress = false;
						fileRes.progress = 0;
						fileRes.result = e.body;
						this.toastService.caseDataUpdated.emit(fileRes.result);
						this.toastService.success(
							this.translatePipe.transform('CRUD.SUCCESS'),
							this.translatePipe.transform('CRUD.MSG.ATTACHMENT.ADDED')
						);
						return fileRes;
					default:
						break;
				}
				if (e instanceof HttpErrorResponse && e.status > 0) {
					fileRes.errorMsg = e.status + ': ' + e.message;
					fileRes.fileName = file.name;
					fileRes.error = e.error;
					fileRes.isProgress = false;
				}
				return fileRes;
			})
		);
		return apiObs;
	}

	removeFile(fileId: number) {
		return this.toastService.toastify(this.api.removeFile(fileId), {
			removed: true,
			success: 'CRUD.MSG.ATTACHMENT.REMOVED',
			caseData: true
		});
	}

	setAsMain(fileId: number) {
		return this.toastService.toastify(this.api.setAsMain(fileId), {
			edited: true,
			success: 'CRUD.MSG.ATTACHMENT.SET_AS_MAIN'
		});
	}

	getDownloadURL(id: number) {
		return `${environment.baseUrl}/pdf-file/download/${id}?t=${this.authService.getToken()}`;
	}
}
