import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { NavigationService } from '@app/services/common/navigation.service';
import { EuconStorageService } from '@app/services/common/eucon-storage.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	constructor(
		private translateService: TranslateService,
		private titleService: Title,
		private _navigationService: NavigationService,
		private euconStorageService: EuconStorageService
	) {}

	ngOnInit(): void {
		this.translateService.addLangs(['en', 'de']);
		let browserDefaultLocale = this.translateService.getBrowserLang();
		let startupLanguage;

		if (this.euconStorageService.getData('language')) {
			startupLanguage = this.euconStorageService.getData('language');
		} else if (browserDefaultLocale && this.translateService.langs.includes(browserDefaultLocale)) {
			startupLanguage = browserDefaultLocale;
		} else {
			startupLanguage = environment.defaultLocale;
		}

		this.translateService.use(startupLanguage);
		this.titleService.setTitle(environment.title);
	}
}
